import { useEffect, useRef, useState } from 'react';
import { handleGAEvent } from '~/utils/helpers';

export const useTrackVisibilityTime = (articleId: number) => {
  const [visibleDuration, setVisibleDuration] = useState(0);
  const [isInView, setIsInView] = useState(false);

  const accumulatedTimeRef = useRef(0);
  const intervalRef = useRef<number | null>(null);
  const observerRef = useRef<IntersectionObserver | null>(null);

  useEffect(() => {
    const element = document.getElementById(articleId.toString());
    if (!element) return;

    const startTracking = () => {
      setIsInView(true);
      if (intervalRef.current) return;

      intervalRef.current = window.setInterval(() => {
        accumulatedTimeRef.current += 3;
        setVisibleDuration(accumulatedTimeRef.current);
      }, 3000);
    };

    const stopTracking = () => {
      setIsInView(false);
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }

      if (accumulatedTimeRef.current > 0) {
        handleGAEvent(`${accumulatedTimeRef.current}-seen`, articleId);
        accumulatedTimeRef.current = 0;
      }
    };

    observerRef.current = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          startTracking();
        } else {
          stopTracking();
        }
      },
      { threshold: 0.5 },
    );

    observerRef.current.observe(element);

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
        observerRef.current = null;
      }
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [articleId]);

  return { isInView, visibleDuration };
};
