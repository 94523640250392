import { FC } from 'react';
import { useUser } from '~/context/UserContext';
import { Tag, TagStatistics } from '~/types';

import styles from './ArticleFormulaScore.module.scss';

interface Props {
  tags: Tag[];
  stats: TagStatistics;
}

const ArticleFormulaScore: FC<Props> = ({ stats, tags }) => {
  const { user } = useUser();

  if (!stats.formula_result_v2) return null;

  const favoriteTagsIds = user?.favorite_tags.map((tag) => tag.id);
  const ignoredTagsIds = user?.ignored_tags.map((tag) => tag.id);
  const articleTagsIds = tags.map((tag) => tag.id);

  const hasFavoriteTag = favoriteTagsIds?.some((favoriteTagId) =>
    articleTagsIds.includes(favoriteTagId),
  );
  const hasIgnoredTag = ignoredTagsIds?.some((favoriteTagId) =>
    articleTagsIds.includes(favoriteTagId),
  );

  const IgnoredTags = tags.filter((tag) => ignoredTagsIds?.includes(tag.id));
  const favoriteTags = tags.filter((tag) => favoriteTagsIds?.includes(tag.id));

  const formulaMultiplier =
    hasFavoriteTag && hasIgnoredTag
      ? 5
      : hasFavoriteTag
      ? 10
      : hasIgnoredTag
      ? 0.5
      : 1;

  const renderTagsTitles = () => {
    if (formulaMultiplier === 10) {
      return favoriteTags.map((tag) => tag.title).join(', ');
    }
    if (formulaMultiplier === 5) {
      return `IGNORED: ${IgnoredTags.map((tag) => tag.title).join(
        ', ',
      )}. FAVORITE: ${favoriteTags.map((tag) => tag.title).join(', ')}`;
    }
    if (formulaMultiplier === 0.5) {
      return IgnoredTags.map((tag) => tag.title).join(', ');
    }
  };

  const formulaResult = stats.formula_result_v2;
  const sum = parseFloat(stats.sum_v2);
  const refCount = parseFloat(stats.references_count_v2);
  const diffs = (sum / refCount).toFixed(2);
  const initialScore = (parseFloat(diffs) + refCount).toFixed(2);
  const personalizedScore = (
    parseFloat(formulaResult) * formulaMultiplier
  ).toFixed(2);

  return (
    <div className={styles.box}>
      <h4 className={styles.statsHeading}>Formula Score: {stats.title}</h4>
      <ul className={styles.statsList}>
        <li className={styles.statsListItem}>
          References count: <br /> <strong>{refCount}</strong>
        </li>
        <li className={styles.statsListItem}>
          Avg FB engagement median diffs:
          <br />
          <strong>
            {sum} / {refCount} = {diffs}
          </strong>
        </li>
        <li className={styles.statsListItem}>
          Initial Formula Score: <br />{' '}
          <strong>
            refs_count + avg_FB_eng_median = {diffs} + {refCount} ={' '}
            {initialScore}
          </strong>
        </li>
        <li className={styles.statsListItem}>
          Finalized Formula Score: <br />
          <strong>
            min(formula_score, 50) = min({initialScore}, 50) = {formulaResult}
          </strong>
        </li>
        <li className={styles.statsListItem}>
          Formula Score Personalization:
          <br />
          <strong>
            {formulaResult} * {formulaMultiplier}{' '}
            <span className={styles.tagsTitles}>
              {formulaMultiplier !== 1 && <>({renderTagsTitles()})</>}
            </span>{' '}
            = {personalizedScore}
          </strong>
        </li>
      </ul>
    </div>
  );
};

export default ArticleFormulaScore;
