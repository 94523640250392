import { FC } from 'react';
import { Reference } from '~/types';

import ArticleReference from '../ArticleReference/ArticleReference';

interface Props {
  articleTitle: string;
  articleUrl: string;
  references: Reference[];
}

const ArticleCardReferences: FC<Props> = ({
  references,
  articleTitle,
  articleUrl,
}) => {
  const uniqueReferences = Array.from(
    new Map(
      references
        .filter((ref) => (ref.reference_title || ref.url) !== articleTitle)
        .map((ref) => [ref.reference_title || ref.url, ref]),
    ).values(),
  );

  return (
    <>
      {!!uniqueReferences?.length &&
        uniqueReferences
          .slice(0, 3)
          .map((reference) => (
            <ArticleReference
              articleUrl={articleUrl}
              key={reference.id}
              reference={reference}
            />
          ))}
    </>
  );
};

export default ArticleCardReferences;
