import { FC } from 'react';
import ReportIcon from '~/icons/ReportIcon';
import { Article } from '~/types';

import styles from './AdminArticleInfo.module.scss';

interface Props {
  article: Article;
}

const AdminArticleInfo: FC<Props> = ({ article }) => {
  return (
    <>
      {article.did_image_failed_test && (
        <p className={styles.imageError}>
          <ReportIcon />
          Image had failed the test
        </p>
      )}
      {article.image_explanation && (
        <p className={styles.imageError}>
          <ReportIcon />
          Explanation: {article.image_explanation}
        </p>
      )}
      {article.is_reference_image_used && (
        <p className={styles.imageError}>
          <ReportIcon />
          Failed to get people images. A placeholder image was used instead.
        </p>
      )}
    </>
  );
};

export default AdminArticleInfo;
