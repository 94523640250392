import { FC } from 'react';
import { Reference } from '~/types';
import { formatHost } from '~/utils/helpers';

import styles from './ArticleReference.module.scss';

interface Props {
  reference: Reference;
  articleUrl: string;
  isInSingle?: boolean;
}

const ArticleReference: FC<Props> = ({ reference, articleUrl, isInSingle }) => {
  const renderReferenceIcon = (reference: Reference) => {
    return new URL(reference.url).host;
  };

  return (
    <div className={styles.box}>
      <div>
        {isInSingle ? (
          <h2 className={styles.title}>
            {reference.reference_title ?? reference.url}
          </h2>
        ) : (
          <a className={styles.link} href={`/article/${articleUrl}`}>
            <h2 className={styles.title}>
              {reference.reference_title ?? reference.url}
            </h2>
          </a>
        )}
        <div className={styles.scraperRow}>
          <img
            src={`https://www.google.com/s2/favicons?domain=${renderReferenceIcon(
              reference,
            )}`}
            className={styles.scraperIcon}
            alt={reference.url}
          />
          <a
            href={reference.url}
            rel='nofollow noreferrer'
            target='_blank'
            className={styles.scraperTitle}
          >
            {formatHost(new URL(reference.url).host)}
          </a>
        </div>
      </div>
      {reference.reference_image_url && (
        <img
          className={styles.image}
          src={reference.reference_image_url}
          alt={reference.reference_title}
        />
      )}
    </div>
  );
};

export default ArticleReference;
