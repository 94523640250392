import { FC } from 'react';
import ArticleReferences from '~/components/Article/ArticleReferences/ArticleReferences';
import ResponsiveImage from '~/components/ResponsiveImage/ResponsiveImage';
import { useUser } from '~/context/UserContext';
import { useTrackVisibility } from '~/hooks/useTrackVisibility';
import { useTrackVisibilityTime } from '~/hooks/useTrackVisibilityTime';
import { Article } from '~/types';

import { Link } from '@remix-run/react';

import ArticleActions from '../ArticleActions/ArticleActions';
import ArticleCardReferences from '../ArticleCardReferences/ArticleCardReferences';
import ArticleFormulaScore from '../ArticleFormulaScore/ArticleFormulaScore';
import ArticleTopicsAndDate from '../ArticleTopicsAndDate/ArticleTopicsAndDate';
import listStyles from './ArticleListCardReferenceBased.module.scss';

interface Props {
  article: Article;
}

const ArticleListCardReferenceBased: FC<Props> = ({ article }) => {
  const { user } = useUser();
  useTrackVisibilityTime(article.id);

  useTrackVisibility(article.id);

  return (
    <div id={article.id.toString()} className={listStyles.card}>
      <article className={listStyles.cardArticle}>
        <div className={listStyles.imageHolder}>
          <Link to={`/article/${article.slug}`} aria-label={article.title}>
            <ResponsiveImage
              mediaData={article.media}
              alt={article.title}
              className={listStyles.img}
            />
          </Link>
        </div>
        <div className={listStyles.cardBottom}>
          <div className={listStyles.dateHolder}>
            <ArticleTopicsAndDate
              tags={article.tags}
              publishedAt={article.published_at}
            />
          </div>
          <Link className={listStyles.link} to={`/article/${article.slug}`}>
            <h2 className={listStyles.heading}>{article.title}</h2>
          </Link>
          {!!article.references?.length && (
            <div className={listStyles.referencesHolder}>
              <ArticleReferences article={article} />
            </div>
          )}
          {!!article.references?.length && (
            <>
              <div className={listStyles.divider}></div>
              <div className={listStyles.mainReferences}>
                <ArticleCardReferences
                  articleUrl={article.slug}
                  references={article.references}
                  articleTitle={article.title}
                />
              </div>
            </>
          )}
          <ArticleActions article={article} slug={article.slug} />
          {user?.is_admin && (
            <ArticleFormulaScore
              stats={article.tag_stats}
              tags={article.tags}
            />
          )}
        </div>
      </article>
    </div>
  );
};

export default ArticleListCardReferenceBased;
