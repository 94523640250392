import { AnimatePresence } from 'framer-motion';
import { FC, useState } from 'react';
import LogInModal from '~/components/LogInModal/LogInModal';
import { useUser } from '~/context/UserContext';
import { Article, Reference } from '~/types';
import { formatHost } from '~/utils/helpers';

import ArticleIgnoreTopicsModal from '../ArticleTopicsModals/ArticleIgnoreTopicsModal';
import styles from './ArticleReferences.module.scss';

const getReferencesTitle = (references: Reference[], article: Article) => {
  try {
    const firstReferenceHost = new URL(article.reference_url).host;
    const formattedHost = formatHost(firstReferenceHost);
    const hostLink = `<a href='${article.reference_url}' target="_blank" rel='noreferrer nofollow'>${formattedHost}</a>`;

    return references.length > 0
      ? `${hostLink} and ${references.length} more`
      : hostLink;
  } catch (e) {
    return `${references.length} references`;
  }
};

interface Props {
  article: Article;
}

const ArticleReferences: FC<Props> = ({ article }) => {
  const [showIgnoreModal, setShowIgnoreModal] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);

  const uniqueReferences = Array.from(
    new Map(
      article.references
        .filter((ref) => (ref.reference_title || ref.url) !== article.title)
        .map((ref) => [ref.reference_title || ref.url, ref]),
    ).values(),
  );

  const { user } = useUser();

  const title = getReferencesTitle(uniqueReferences, article);

  const renderReferenceIcon = (reference: Reference) => {
    return new URL(reference.url).host;
  };

  const first3referencesFavicons = [
    { id: article.id, url: article.reference_url },
    ...uniqueReferences,
  ]
    .reduce<Reference[]>((uniqueReferences, reference) => {
      const isDuplicate = uniqueReferences.some(
        (uniqueRef) =>
          new URL(uniqueRef.url).host === new URL(reference.url).host,
      );
      if (!isDuplicate) {
        uniqueReferences.push(reference);
      }
      return uniqueReferences;
    }, [])
    .slice(0, 3);

  return (
    <div className={styles.row}>
      <div className={styles.innerRow}>
        {!!first3referencesFavicons.length && (
          <div className={styles.favIconsRow}>
            {first3referencesFavicons.map((reference) => (
              <img
                className={styles.favIcon}
                key={reference.id}
                src={`https://www.google.com/s2/favicons?domain=${renderReferenceIcon(
                  reference,
                )}`}
                alt={reference.url}
              />
            ))}
          </div>
        )}
        <div
          className={styles.title}
          dangerouslySetInnerHTML={{
            __html: title,
          }}
        ></div>
      </div>
      <button
        className={styles.actionButton}
        onClick={() => {
          if (!user) {
            return setShowLoginModal(true);
          }
          setShowIgnoreModal(true);
        }}
      >
        See less like this
      </button>
      <AnimatePresence>
        {showIgnoreModal && (
          <ArticleIgnoreTopicsModal
            tags={article.tags}
            onClose={() => setShowIgnoreModal(false)}
          />
        )}
        {showLoginModal && (
          <LogInModal onClose={() => setShowLoginModal(false)} />
        )}
      </AnimatePresence>
    </div>
  );
};

export default ArticleReferences;
