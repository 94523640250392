import { useEffect, useState } from 'react';
import { handleGAEvent } from '~/utils/helpers';

export const useTrackVisibility = (articleId: number) => {
  const [hasBeenSeen, setHasBeenSeen] = useState(false);

  useEffect(() => {
    if (hasBeenSeen) return;

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          handleGAEvent('seen', articleId);
          setHasBeenSeen(true);
          observer.disconnect();
        }
      },
      { threshold: 0.5 },
    );

    const element = document.getElementById(articleId.toString());
    if (element) observer.observe(element);

    return () => observer.disconnect();
  }, [articleId, hasBeenSeen]);
};
